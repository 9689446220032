import api from "../clients/axios";

const resource = "linear-feet";

export default {
  async calculate(body) {
    const response = await api.post(`v1/${resource}/calculate`, body);
    return response.data;
  },
};
