import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';
import linearFeet from './modules/linearFeet';

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  linearFeet,
});
