import Repository from '../../services/repositories/RepositoryFactory';
import { errorMessage } from "../../utils/ErrorMessages";

const linearFeet = Repository.service("linearFeet");

export default {
  namespaced: true,
  state: {
    emptyFieldsLineItems: [
      {
        container_name: false,
        hu_count: false,
        lenght: false,
        width: false,
        height: false,
        weight: false,
      },
    ],
    containerSelected: {}
  },
  getters: {
    getEmptyFieldsLineItems(state) {
      return state.emptyFieldsLineItems;
    },
    getContainerSelected(state) {
      return state.containerSelected;
    },
  },
  mutations: {
    setEmptyFieldsLineItems(state, payload) {
      state.emptyFieldsLineItems = payload;
    },
    setContainerSelected(state, payload) {
      state.containerSelected = payload;
    },
  },

  actions: {
    async calculate(_, body) {
      try {
        const response = await linearFeet.calculate(body);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail);
      }
    },

  },
};
