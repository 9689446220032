import swal from "sweetalert";

export function errorMessage(message) {
  swal({
    title: `${message}`,
    text: "Please, try again.",
    icon: "error",
    dangerMode: true,
  });
}
export function generalMessage(event) {
  swal({
    title: 'Good Job!',
    text: `${event}`,
    icon: "success",
  });
}
