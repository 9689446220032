import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.VUE_APP_BACK_ROUTE}/api/`,
});

const responseMock = {
  data: {
    access_token: 'a559b5e5-1ea6-4d71-9fff-6508e4d23703',
  },
};

const injectToken = (config) => {
  try {
    // const response = await UserRepository.getUser(1);
    const axiosConfig = config;
    axiosConfig.headers["access-token"] = `${responseMock.data.access_token}`;
    return axiosConfig;
  } catch (error) {
    throw new Error('Unauthorized');
  }
};

api.interceptors.request.use(injectToken);

export {
  api as default,
};
